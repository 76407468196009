@import '../../../../../stylesheets/themes/ecosystem-default/colors';

.textSection {
  width: 100%;
  padding: 30px 10px;
  margin: 0 auto;
  background-color: white;
  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 20px 10px;
  }

  @media (max-width: 480px) {
    padding: 15px 10px;
  }
}

.textTitle {
  font-size: 2em;
  font-weight: 600;
  color: $ext-grey-700;
  margin-bottom: 14px;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 1.5em;
  }

  @media (max-width: 480px) {
    font-size: 1.2em;
  }
}

.textParagraph {
  font-size: 1.1em;
  line-height: 1.8;
  color: #333;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 1em;
  }

  @media (max-width: 480px) {
    font-size: 0.9em;
  }
}

.ctaButtons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  transform: translateX(-5%);

  @media (max-width: 480px) {
    margin-top: 15px;
  }
}

.orText {
  margin: 0 15px;
  color: #333;

}
