@import 'stylesheets/themes/ecosystem-default/colors';
@import 'stylesheets/base/spacing';

.EcosystemApp {
  display: grid;
  grid-template-areas:
    'header'
    'topbar'
    'toolbar'
    'content'
    'footer';
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto 1fr auto;
  min-height: 100vh;

  &__Body {
    margin-top: 78px;
    width: calc(100vw - 17px); /* minus scrollbar width */
    overflow-x: hidden;

    &__MainContent {
      width: 100%;
      min-height: calc(100vh - 78px - 76px - 21px);
    }
  }

  &__gfklogo {
    color: $bright-orange;
  }

  &__Error {
    &__Content {
      padding-left: $spacing-small;
    }
  }
}

@media screen and (max-width: 390px) {
  .EcosystemApp {
    display: grid;
    grid-template-areas:
      'header'
      'topbar'
      'toolbar'
      'content'
      'footer';
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto 1fr auto;
    min-height: 100vh;
    max-width: 390px;
  }

  .EcosystemApp__Body {
    margin-top: 78px;
    width: calc(100vw - 17px); /* minus scrollbar width */
  }

  .EcosystemApp__Body__MainContent {
    width: 100%;
    min-height: calc(100vh - 78px - 76px - 21px);
  }

  .EcosystemApp__gfklogo {
    color: $bright-orange;
  }

  .EcosystemApp__Error {
    &__Content {
      padding-left: $spacing-small;
    }
  }
}
